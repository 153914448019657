.blog-list{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}


.blog-card {
    width: auto;  /* Set card width */
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;  /* Prevent content overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px;
    padding: 20px;
    background-color:black;
  }
  
  .blog-image {
    width: 100%;  /* Make image responsive */
    height: 200px; 
    object-fit: cover; 
    border-radius: 20px; /* Ensure the image fits well */
  }
  
  .blog-card h2, 
  .blog-card h3 {
    margin: 10px 0;
    color: #000;
  }
  
  .blog-card p {
    font-size: 14px;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 80px;
    color: #000;
  }
  


  .truncate-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;  /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  
  /* Header Section */
.blog-header {
  min-height: 400px;
  background: #F8FAFC;
  text-align: center;
  padding: 20px;
}

.blog-header h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
}

.blog-header p {
  font-size: 1.2rem;
  color: #555;
}

/* Blog Container */
.blog-container {
  padding: 40px 0;
}

/* Blog Card */
.blog-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.blog-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 20px;
}

.blog-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.blog-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Truncate to 3 lines */
  -webkit-box-orient: vertical;
}

/* Load More Button */
.btn-load-more {
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-load-more:hover {
  background-color: #0056b3;
}

.btn-load-more:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}